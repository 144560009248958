import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import CarrierFrontOfficeManager from "../components/CarrierFrontOfficeManager"

const CarrierFrontOfficeManagerPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} textShadow={true} bgOffset="-200"/>
          <CarrierFrontOfficeManager data={fields} content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(CarrierFrontOfficeManagerPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        pageTitle
        pageSubtitle
        date
        description
        image1 {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "bg/sky_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`